.header {
  max-width: 1100px;
  width: 95%;
  margin: 0 auto;
  justify-content: space-between;
  padding: 10px;

}

@media (max-width: 600px) {
  .header {
    height: 6em;
  }
}
