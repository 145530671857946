.troop {
  padding: 2em;
  margin: 0 auto;
  text-align: center;
  box-shadow: var(--shadow);
  transition: transform 0.2s linear;
}

.troop:hover {
  transform: translateY(-7px);
}

.troop__description {
  margin-top: 1em;
  text-align: left;
}

.troop__age {
  font-style: normal;
}

.troop__join {
  font-style: normal;
}

.troop__meetingtime {
  font-size: small;
}
.troop__gender {
  font-size: small;
  font-style: italic;

}

.troop__stack {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 1.2em 0;
}

.troop__stack-item {
  margin: 0.5em;
  font-weight: 500;
  font-size: 0.8rem;
  color: var(--clr-fg-alt);
}


.troop .link--icon {
  margin-left: 0.5em;
}
