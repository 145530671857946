.oath {
  flex-direction: column;
  margin-top: 3em;
}

.oath__title {
  color: var(--clr-primary);
  text-align: center;
}

.oath__desc {
  font-size: 1rem;
  max-width: 600px;
}

.oath__desc,
.oath__contact {
  margin-top: 2.4em;
}

.oath .link--icon {
  margin-right: 0.8em;
}

.oath .btn--outline {
  margin-right: 1em;
}

@media (max-width: 600px) {
  .app .oath {
    align-items: flex-start;
    margin-top: 2em;
  }
}
